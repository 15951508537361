<template>
    <div>
        <ts-page-title :title="$t('repairProductProfile.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-end">
                    <Button type="info" ghost @click="addNew" v-if="false">
                        {{ $t('addNew') }}
                    </Button>
                    <div class="tw-justify-end tw-space-x-3 tw-flex">
                        <div class="row tw-w-80">
                            <label
                                class="tw-text-xs col-md-4 tw-whitespace-nowrap tw-py-2 tw-text-right"
                                >{{ $t('serviceTicket.repairStatus') }} :
                            </label>
                            <div class="col-md-8">
                                <Select
                                    class="tw-w-60"
                                    v-model="repair_status"
                                    multiple
                                    :max-tag-count="1"
                                    :placeholder="$t('all')"
                                    @on-change="fetchData"
                                >
                                    <Option
                                        v-for="repairStatus in repairStatusList"
                                        :value="repairStatus.repair_status_id"
                                        :key="repairStatus.repair_status_id"
                                        :label="repairStatus.repair_status"
                                    >
                                        {{ repairStatus.repair_status }}
                                    </Option>
                                </Select>
                            </div>
                        </div>
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('repairProductProfile.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                        <ts-button
                            v-tooltip="$t('filter')"
                            @click.prevent="visible = true"
                        >
                            <i class="fa fa-filter"></i>
                        </ts-button>
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="ticket_no">
                    {{ row.service_ticket ? row.service_ticket.ticket_no : '' }}
                </template>
                <template slot-scope="{ row }" slot="ticket_no">
                    {{
                        row.service_ticket
                            ? row.service_ticket.service_date
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="product_name_en">
                    {{ row.product ? row.product.product_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="expected_completion_date">
                    {{
                        row.service_ticket
                            ? row.service_ticket.expected_completion_date
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="repair_status">
                    <span
                        class="badge"
                        :class="repairStatusColor(row.repair_status)"
                        >{{
                            row.repair_product_profiles_status
                                ? row.repair_product_profiles_status
                                      .repair_status
                                : ''
                        }}</span
                    >
                </template>
                <template slot-scope="{ row }" slot="repaired_by">
                    {{
                        row.repaired_by_name
                            ? row.repaired_by_name.employee_name_en
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        v-if="isUpdateRepairStatus(row.repair_status)"
                        href="#"
                        class="text-primary"
                        @click.prevent="changeRepairStatus(row)"
                    >
                        <Icon
                            v-tooltip="$t('repairProductProfile.diagnosed')"
                            v-if="row.repair_status == 2"
                            type="md-checkmark-circle"
                            size="20"
                        />
                        <Icon
                            v-tooltip="$t('repairProductProfile.repaired')"
                            v-if="row.repair_status == 6"
                            type="md-checkbox"
                            size="20"
                        />
                    </a>
                    <a
                        v-if="row.repair_status == 2"
                        href="#"
                        class="text-primary"
                        v-tooltip="$t('repairProductProfile.diagnostic')"
                        @click.prevent="performDiagnostic(row)"
                    >
                        <Icon type="ios-construct" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                scrollable
                :mask="false"
                footer-hide
                width="1000px"
                :title="$t('repairProductProfile.diagnostic')"
                centered
                :zIndex="1020"
                :closable="false"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>

        <!-- form-action -->
        <Modal
            v-model="showFormRepairStatus"
            draggable
            scrollable
            :mask="false"
            footer-hide
            width="500px"
            :title="$t('repairProductProfile.repairStatus')"
            centered
            :zIndex="1020"
            :closable="false"
        >
            <div class="tw-space-y-2">
                <div class="row">
                    <div class="col-md-12">
                        <label class="tw-text-xs">{{
                            $t('repairProductProfile.repairStatus')
                        }}</label>
                        <Select
                            v-model="model.repair_status"
                            class="col-md-12"
                            @on-change="onChangeRepairStatus()"
                        >
                            <Option
                                v-for="repairStatus in getRepairStatusList(
                                    model.repair_status
                                )"
                                :value="repairStatus.repair_status_id"
                                :key="repairStatus.repair_status_id"
                                >{{ repairStatus.repair_status }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback tw-text-xs"
                            v-if="errors.has('location_name_kh')"
                        >
                            {{ errors.first('location_name_kh') }}
                        </div>
                    </div>
                </div>
                <div class="row" v-if="model.repair_status == 7">
                    <div class="col-md-12">
                        <label class="required tw-text-xs">{{
                            $t('repairProductProfile.completionDate')
                        }}</label>
                        <DatePicker
                            :value="model.completion_date"
                            type="datetime"
                            style="width: 100%"
                            placeholder="DD-MM-YYYY HH:mm:ss"
                            format="dd-MM-yyyy HH:mm:ss"
                            @on-change="onChangeCompletionDate"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('completion_date')"
                        >
                            {{ errors.first('completion_date') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 tw-mt-3 tw-text-right">
                        <ts-button
                            class="tw-mr-2"
                            @click.prevent="
                                () => (showFormRepairStatus = false)
                            "
                        >
                            {{ $t('cancel') }}
                        </ts-button>
                        <ts-button
                            color="primary"
                            @click.prevent="onUpdateRepairStatus"
                            :waiting="waiting"
                            >{{ $t('update') }}</ts-button
                        >
                    </div>
                </div>
            </div>
        </Modal>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="row tw-mb-3">
                <div class="col-md-12">
                    <label class="">{{ $t('warehouse.branch') }}</label>
                    <Select
                        v-model="branch_id"
                        class="tw-w-full"
                        filterable
                        multiple
                        :max-tag-count="1"
                    >
                        <Option
                            v-for="branch in branchs"
                            :value="branch.value"
                            :key="branch.value"
                        >
                            {{ branch.label }}
                        </Option>
                    </Select>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import FromAction from './form'
import expandRow from './table-expand.vue'
import moment from 'moment'

export default {
    name: 'repairProductProfile',
    components: {
        FromAction
    },
    data () {
        return {
            waiting: false,
            loading: false,
            showForm: false,
            showFormRepairStatus: false,
            errors: new Errors(),
            branchs: [],
            width: 'width: 300px',
            visible: false,
            model: {
                service_ticket_id: null,
                repair_product_id: null,
                repair_status: null,
                completion_date: null
            }
        }
    },
    created () {
        this.fetchFilterData()
    },
    computed: {
        ...mapState('customerService/repairProductProfile', [
            'resources',
            'pagination',
            'formViewDatas'
        ]),
        ...mapGetters('customerService/repairProductProfile', [
            'getRepairStatusList'
        ]),
        search: {
            get () {
                return this.$store.state.customerService.repairProductProfile
                    .search
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/repairProductProfile/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'customerService/repairProductProfile/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    type: 'expand',
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        })
                    },
                    align: 'center',
                    width: 50
                },
                {
                    title: this.$t('repairProductProfile.branch'),
                    slot: 'branch',
                    minWidth: 120,
                    sortable: true
                },
                {
                    title: this.$t('repairProductProfile.ticketNo'),
                    slot: 'ticket_no',
                    minWidth: 180,
                    sortable: true
                },
                {
                    title: this.$t('repairProductProfile.ticketDate'),
                    slot: 'ticket_no',
                    minWidth: 180,
                    sortable: true
                },
                {
                    title: this.$t('repairProductProfile.productName'),
                    slot: 'product_name_en',
                    minWidth: 220,
                    sortable: true
                },
                {
                    title: this.$t('repairProductProfile.serial'),
                    key: 'serial_no',
                    minWidth: 220,
                    sortable: true
                },
                {
                    title: this.$t('repairProductProfile.qty'),
                    key: 'quantity',
                    minWidth: 100,
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'repairProductProfile.expectedCompletionDate'
                    ),
                    slot: 'expected_completion_date',
                    minWidth: 220,
                    sortable: true
                },
                {
                    title: this.$t('serviceTicket.completionDate'),
                    key: 'completion_date',
                    align: 'center',
                    minWidth: 180
                },
                {
                    title: this.$t('repairProductProfile.repairStatus'),
                    slot: 'repair_status',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('repairProductProfile.repairedBy'),
                    slot: 'repaired_by',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('repairProductProfile.remarks'),
                    key: 'remarks',
                    minWidth: 100
                },

                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    minWidth: 100
                }
            ]
        },
        branch_id: {
            get () {
                return this.$store.state.customerService.repairProductProfile
                    .branch_id
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/repairProductProfile/SET_BRANCH_ID',
                    newValue
                )
            }
        },
        repairStatusList () {
            return this.formViewDatas.repairProductProfilesStatus
        },
        repair_status: {
            get () {
                return this.$store.state.customerService.repairProductProfile
                    .repair_status
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/repairProductProfile/SET_REPAIR_STATUS',
                    newValue
                )
            }
        }
    },
    methods: {
        ...mapActions('customerService/repairProductProfile', [
            'getFormViewModel'
        ]),
        repairStatusColor (value) {
            switch (value) {
                case 1:
                    return 'bg-secondary'
                case 2:
                    return 'bg-warning'
                case 3:
                    return 'bg-success'
                case 4:
                    return 'bg-green'
                case 5:
                    return 'bg-danger'
                case 6:
                    return 'bg-info'
                case 7:
                    return 'tw-bg-green-600'
                default:
                    return 'bg-secondary'
            }
        },
        onChangeCompletionDate (value) {
            this.model.completion_date = value
        },
        isUpdateRepairStatus (repair_status) {
            if ([2, 6].includes(repair_status)) {
                return true
            }
            return false
        },
        onChangeRepairStatus (value) {
            console.log(value)
            // if (value == 1) {
            //     this.model.completion_date = null
            // }
        },
        changeRepairStatus (value) {
            let repair_status = value.repair_status == 2 ? 3 : 7
            this.model.service_ticket_id = value.service_ticket_id
            this.model.repair_product_id = value.repair_product_id
            this.model.repair_status = repair_status
            this.model.completion_date = value.completion_date
                ? (this.model.completion_date = moment(
                      value.completion_date,
                      'DD-MM-YYYY hh:mm:ss a'
                  ).format('DD-MM-YYYY HH:mm:ss'))
                : null
            // this.getFormViewModel({
            //     index: 'employee',
            //     params: {
            //         fnName: 'employee'
            //     }
            // })
            this.showFormRepairStatus = true
        },
        fetchFilterData () {
            this.getFormViewModel({
                index: 'repairProductProfilesStatus',
                params: {
                    fnName: 'repairProductProfilesStatus'
                }
            })
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssignedForFilter')
                .then(response => {
                    this.branchs = response.data.map(el => ({
                        value: el.branch_id,
                        label: el.branch_name_en
                    }))
                    this.branch_id = [
                        this.$store.state.authUser.user.default_branch.branch_id
                    ]
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('customerService/repairProductProfile/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        addNew () {
            this.$store.commit(
                'customerService/repairProductProfile/SET_EDIT_DATA',
                {}
            )
            this.$refs.form_action.fetchResources()
            this.showForm = true
        },
        performDiagnostic (record) {
            this.showForm = true
            this.$store.commit(
                'customerService/repairProductProfile/SET_EDIT_DATA',
                record
            )
            this.$refs.form_action.setEditData()
            this.$refs.form_action.fetchResources()
        },
        clearEdit () {
            this.$refs.form_action.clearInput()
            this.$store.commit(
                'customerService/repairProductProfile/SET_EDIT_DATA',
                {}
            )
            this.showForm = false
        },
        onUpdateRepairStatus () {
            this.waiting = true
            this.$store
                .dispatch(
                    'customerService/repairProductProfile/updateRepairStatus',
                    {
                        id: this.model.repair_product_id,
                        data: this.model
                    }
                )
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                    this.showFormRepairStatus = false
                    this.waiting = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                    this.waiting = false
                })
        },
        deleteRecord (record) {
            record._deleting = true
            this.$store
                .dispatch(
                    'customerService/repairProductProfile/destroy',
                    record.service_ticket_id
                )
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'REPARI PRODUCT',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500),
        branch_id: debounce(function () {
            this.fetchData(this.search)
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('customerService/repairProductProfile/RESET_STATE')
        next()
    }
}
</script>
